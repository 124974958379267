<template>
	<div
		ref="post_tv_manage_upload"
		class=" flex-column overflow-y-auto"
	>
		<div
			class="mt-50 full-height  pa-20"
		>
			<div class="text-center mt-20 pt-150 character2">
				<div class="size-px-18 color-blue-mafia font-weight-700">{{ $language.tv.txt_tv_upload }} <!-- 마피아TV에 어떤 동영상을 올리고 싶으신가요? --></div>
			</div>
			<div class="mt-30">
				<div
					class="justify-start items-center gap-10 pa-15-20 box radius-20"
					@click="toItem"
				>
					<div><v-icon>mdi-play-box-outline</v-icon></div>
					<div>
						<div class="size-px-13 color-gray02">{{ $language.tv.txt_tv_upload_alone }} <!-- 한편의 영상만 올린다면 --></div>
						<div class="size-px-16 color-gray-dark02 font-weight-500">{{ $language.tv.title_tv_upload_alone }} <!-- 단편 동영상 --></div>
					</div>
				</div>

				<div
					class="mt-10 justify-start items-center gap-10 pa-15-20 box radius-20"
					:class="{ 'on-upload': mode == 'series'}"
					@click="getData"
				>
					<div class="position-relative">
						<span class="position-relative " style="z-index: 1" ><v-icon :class="{ 'color-blue02' : mode == 'series'}">mdi-play-box-outline</v-icon></span>
						<span class="position-absolute" style="left: -3px; top: 3px">
							<v-icon :class="{ 'color-blue02' : mode == 'series'}">mdi-checkbox-blank-outline</v-icon>
							<div class="bg-white position-absolute-full" style="top: -6px; left: 6px"></div>
						</span>
					</div>
					<div>
						<div class="size-px-13 color-gray02">{{ $language.tv.txt_tv_upload_series }} <!-- 여러개의 스토리 동영상을 올린다면 --></div>
						<div class="size-px-16 color-gray-dark02 font-weight-500" :class="{ 'color-blue02' : mode == 'series'}">{{ $language.tv.title_tv_upload_series }} <!-- 시리즈 동영상 --></div>
					</div>
				</div>
			</div>
			<div class="mt-30">
				<div
					v-if="mode == 'series'"
					class="top-line"
				>
					<div class="mt-30 size-px-16 font-weight-500 color-blue-mafia text-center mb-30">{{ $language.tv.txt_tv_upload_series_select }} <!-- 시리즈를 선택하세요.--></div>
					<ul
						v-if="items_series.length > 0"
						class="mb-80"
					>
						<li
							v-for="(series, index) in items_series"
							:key="'series_' + index"
							class="justify-space-between gap-10 items-center under-line-not-last pb-15 mb-15"
						>
							<div class="position-relative">
								<span class="position-relative " style="z-index: 1" ><v-icon class="color-gray01">mdi-play-box-outline</v-icon></span>
								<span class="position-absolute" style="left: -3px; top: 3px">
									<v-icon class="color-gray01">mdi-checkbox-blank-outline</v-icon>
									<div class="bg-white position-absolute-full" style="top: -6px; left: 6px"></div>
								</span>
							</div>
							<div class="flex-1 size-px-14 color-gray-dark02 font-weight-500" @click="toEpisode(series)">{{ series.title }}</div>
							<div
								@click="onMore(series)"
								class="color-gray02 size-px-12 font-weight-400"
							>
								Edit
							</div>
						</li>
					</ul>
					<Empty
						v-else
					></Empty>
				</div>
				<div
					v-else
					class="size-px-13 color-gray-dark02 font-weight-400"
				>
					{{ $language.tv.txt_tv_upload_info }} <!-- 신규 시리즈를 추가하게 되면 시리즈 별로 동영상을 올릴 수 있습니다. 시리즈로 등록된 영상은 마피아TV 내 시리즈, 단편 두 곳에서 확인이 가능합니다.-->
				</div>
			</div>
		</div>
		<div
			v-if="mode == 'series'"
			class="position-fixed pa-20 width-100 bg-white"
			style="z-index: 1; bottom: 0px"
		>
			<div class="btn_wrap">
				<button
					class="btn_l btn_fill_blue justify-center items-center gap-5"
					@click="toSeries"
				><v-icon class="color-white">mdi-plus</v-icon> 신규 시리즈 추가</button>
			</div>
		</div>

		<PopupHalf
			v-if="is_on_more"
			:user="user"
			:title="title_half"

			@cancel="offMore"
		>
			<template
				v-slot:half_items
			>
				<li class="pb-10" @click="toEdit">{{ $language.common.modify}} <!-- 수정 --></li>
				<li @click="onDelete">{{ $language.common.delete}} <!-- 삭제 --></li>
			</template>
		</PopupHalf>

		<Popup_confirm
			v-if="is_on_delete"
			@cancel="is_on_delete = false"
			@click="postDelete"
		>
			<template v-slot:title>{{ $language.tv.title_tv_series_delete}} <!-- 시리즈 삭제 --></template>
			<template
				v-slot:main-txt
			>{{ $language.tv.txt_tv_series_delete}} <!-- 선택한 시리즈를 삭제 하겠습니까?--></template>
			<template
				v-slot:sub-txt
			>{{ $language.tv.txt_tv_series_delete_sub}} <!-- 삭제 시 포함된 동영상도 삭제 됩니다. --></template>
		</Popup_confirm>

		<ScrollPagination
			v-if="mode == 'series' && $refs.post_tv_manage_upload"
			:body="$refs.post_tv_manage_upload"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"
			:top="$refs.cartel_cover"

			@change="getSearchData"
		></ScrollPagination>
	</div>
</template>

<script>
	import Empty from "@/view/Layout/Empty";
	import PopupHalf from "@/view/Layout/PopupHalf";
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	import ScrollPagination from "@/components/ScrollPagination";
	export default {
		name: 'MafiaTvUpload'
		, components: {ScrollPagination, Popup_confirm, PopupHalf, Empty}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'MafiaTvUpload'
					, title: this.$language.tv.title_tv_item
					, type: 'cartel_sub'
					, not_footer: true
					, from: {
						name: 'MafiaTvManageList'
						, params: {
							idx: this.$route.params.idx
							, b_code: this.$route.params.code
							, b_id: this.$route.params.b_id
						}
					}
				}
				, item_search: {
					page_number: 1
					, pagerecnum: 10
				}
				, items_series: []
				, mode: ''
				, max: false
				, is_add: true
				, is_on_more: false
				, item_more: {}
				, is_on_delete: false
			}
		}
		, computed: {
			title_half: function(){
				let t = ''
				if(this.item_more.title){
					t = this.item_more.title
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.mode = 'series'

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_tv_manage_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, catgy_code: 'CA04300002'
							, post_level: 1
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if(result.success){
						if(result.data.post_list.length > 0){
							if(this.item_search.page_number == 1){
								this.items_series = result.data.post_list
							}else{
								this.items_series = this.items_series.concat(result.data.post_list)
							}
							this.max = false
						}else{
							if(this.item_search.page_number == 1) {
								this.items_series = []
							}
							this.max = true
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toSeries: function(){
				this.$bus.$emit('to', {name: 'MafiaTvSeries', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: this.mode}})
			}
			, toItem: function(){
				this.$bus.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: 'alone'}})
			}
			, onMore: function(item){
				this.item_more = item
				this.is_on_more = true
			}
			, offMore: function(){
				this.item_more = {}
				this.is_on_more = false
			}
			, onDelete: function(){
				this.is_on_delete = true
			}
			, toEdit: function(){
				this.$emit('to', {name: 'MafiaTvSeries', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: this.item_more.bulletin_number}})
			}
			, postDelete: async function(){

			}
			, toEpisode: function(item){
				this.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: item.bulletin_number, type: 'episode'}})
			}
			, getSearchData: function(page){
				this.item_search.page_number = page
				this.getData()
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
		}
	}
</script>

<style>
	.on-upload {
		border-color: var(--blue02);
	}
</style>